<div class="ongoing-course p-4 mb-4 tour-on-going-course">
  <h4>current course</h4>
  <ng-container *isFetchingData="loading$">
    <ng-container *ngIf="courses?.length; else noCourse">
      <div class="ongoing-left-content" (click)="onClickNext(course)" *ngFor="let course of courses">
        <img id="ongoing-course-image" src="{{course.courseImageUrl}}" alt="Course Image" class="img-fluid">
        <span id="ongoing">ongoing</span>
        <div class="ongoing-right-content">
          <div class="ongoing-right-content-top">
            <div class="executive">
              <h5>{{course?.courseName}}</h5>
              <span>{{course?.courseDesc}}</span>
            </div>
            <div class="learn">
              <span>Component {{course?.currentCourseComponent?.courseComponent?.sequence}} : {{getComponentStep(course)}}</span>
              <a href="javascript:;" id="component-learn"><fa-icon class="tour-start-course" [icon]="['fas', 'arrow-right']"></fa-icon></a>
              <div id="progress-bar">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" style="width: {{course?.currentCourseComponent?.progress}}%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ongoing-right-content-bottom">
            <div class="component-progress">
              <h6>Component {{course?.currentCourseComponent?.courseComponent?.sequence}}: {{course?.currentCourseComponent?.courseComponent?.name}}</h6>
              <span class="progress-num">
                <span id="num-highlight">
                  {{course?.currentCourseComponent?.courseComponent?.sequence}}
                </span>/ {{course?.courseTotalComponents}}
              </span>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: {{getCoursePercent(course)}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noCourse>
      <div class="no-course">
        <h6>No course available</h6>
      </div>
    </ng-template>
  </ng-container>
</div>
